import React from 'react'
import PropTypes from 'prop-types'
import { Link } from "gatsby"
import styled, { css } from 'styled-components'
import Img from "gatsby-image"

import Content from 'src/components/molecules/Content'
import Shared from 'src/components/molecules/Shared'

import Settings from 'src/styles/settings'

import { Media } from 'src/styles/mixins'

const Container = styled.div`

  ${Media.desktop`
    margin-bottom: 2rem;
  `}

  ${Media.mobile`
    margin-bottom: 1rem;
  `}
`;

const Inner = styled.div`
  max-width: 1068px;
  margin: 0 auto;

  ${Media.desktop`
    padding-top: 4rem;
    position: relative;
  `}

  ${Media.mobile`
    padding-top: 2rem;
  `}
`;

const List = styled.ul`
  text-align: center;
  font-size: 1rem;
  padding: 0;

  ${Media.desktop`
    margin: 0 0 1rem;
  `}

  ${Media.mobile`
    margin: 0 0 .5rem;
  `}
`;

const Item = styled.li`
  display: inline-block;
  margin: 0 .5rem;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
`;

const ItemLink = styled(Link)`
  color:${Settings.colors.keyColor};
  text-decoration: none;
`;

const Title = styled.h1`
  text-align: center;
  max-width: 728px;
  padding: 0 1rem;

  ${Media.desktop`
    margin: 0 auto 3rem;
  `}

  ${Media.mobile`
    margin: 0 auto 1rem;
    font-size: 1.14rem;
  `}
`;

const Thumbnail = styled.div`

  ${Media.desktop`
    margin-bottom: 2rem;
  `}

  ${Media.mobile`
    margin-bottom: 1rem;
  `}
`;

const Image = styled(Img)`
  width: 100%;

  ${Media.desktop`
    border-radius: .5rem;
  `}
`;

const Featured = ({ title, excerpt, url, tags, image, twitter, settings }) => (
  <Container>
    <Inner>
      <List>
        { tags &&
          tags.map(( tag ) => {
            if (tag.group === 1) {
              return <Item><ItemLink to={`/tag/${tag.slug}`} dangerouslySetInnerHTML={{ __html: tag.title }} /></Item>
            }
          })
        }

      </List>

      <Title dangerouslySetInnerHTML={{ __html: title }} />

      { image &&
        <Thumbnail>
          <Image fluid={ image } />
        </Thumbnail>
      }

      <Content body={ excerpt } />

      { url &&
        <Shared title={ title } url={ url } twitter={ twitter } settings={ settings } />
      }
    </Inner>
  </Container>
)

Featured.propTypes = {
  title: PropTypes.string,
  excerpt: PropTypes.string,
  url: PropTypes.string,
  tags: PropTypes.array,
  image: PropTypes.object,
  twitter: PropTypes.string,
  settings: PropTypes.object,
}

export default Featured
