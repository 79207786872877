import React from 'react'
import { Link } from "gatsby"
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import Settings from 'src/styles/settings'

import { Media } from 'src/styles/mixins'

const Container = styled.div`
  display: table;
  width: 100%;
  margin-bottom: 2rem;

  ${Media.mobile`
    display: block;
  `}
`;

const List = styled.ul`
  display: table-cell;
  letter-spacing: -1em;

  ${Media.mobile`
    display: block;
  `}
`;

const Item = styled.li`
  display: inline-block;
  margin: 0 .4em .4em 0;
`;

const ItemLink = styled(Link)`
  display: inline-block;
  padding: .2em .6em;
  border: 1px solid ${Settings.colors.borderColor};
  border-radius: .2em;
  color: ${Settings.colors.textColor};
  font-size: ${Settings.fonts.fontSizeSmall};
  text-decoration: none;
  letter-spacing: normal;

  &:hover {
    background-color: ${Settings.colors.keyColor};
    color: ${Settings.colors.whiteColor};
  }
`;

const Date = styled.div`
  display: table-cell;
  color: ${Settings.colors.textLightColor};
  font-family: ${Settings.fonts.defaultFontFamilyEn};
  text-align: right;
  vertical-align: bottom;

  ${Media.mobile`
    display: block;
  `}
`;

const PostInfo = ({ tags, date }) => (
  <Container>
    <List>
      { tags && tags.map(( tag ) => (
        <Item key={ tag.id }>
          <ItemLink to={`/tag/${ tag.slug }`} title={ tag.title } dangerouslySetInnerHTML={{ __html: tag.title }} />
        </Item>
      ))}
    </List>
    <Date dangerouslySetInnerHTML={{ __html: date }} />
  </Container>
)

PostInfo.propTypes = {
  tags: PropTypes.array,
  date: PropTypes.string,
}

export default PostInfo
