import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import Settings from 'src/styles/settings'

import { Media } from 'src/styles/mixins'

import Ads from 'src/components/atoms/Ads'
import PostInfo from 'src/components/molecules/PostInfo'

const Container = styled.div`
  max-width: 728px;
  margin: 0 auto;
  padding: 0 1rem;

  ${Media.desktop`
    margin-bottom: 2rem;
  `}

  ${Media.mobile`
    margin-bottom: 1rem;
  `}

  & > *:first-child {
    margin-top: 0;
  }

  & > *:last-child {
    margin-bottom: 0;
  }

  & > h1,
  & > h2,
  & > h3,
  & > h4,
  & > h5,
  & > h6 {
    margin: 1.6em 0 .6em;
    font-weight: bold;
  }

  & > h1 {
    position: relative;
    padding-bottom: .6em;
    font-size: ${Settings.fonts.fontSizeXlarge};
    &::before {
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: -1;
      width: 1.8em;
      height: .14em;
      border-radius: 100px;
      background-color: ${Settings.colors.keyColor};
      content: "";
    }
  }

  & > h2 {
    font-size: ${Settings.fonts.fontSizeLarge};
  }

  & > h3 {
    font-size: ${Settings.fonts.fontSizeMedium};
  }

  p {
    margin: 1.5rem 0;

    & img {
      border-radius: .5rem;

      ${Media.desktop`
        width: 110%;
        margin-left: -5%;
      `}

      ${Media.mobile`
        width: 100%;
      `}
    }

    & a {
      word-break: break-all;
    }
  }

  .embed,
  [class^="embed-"],
  [class*=" embed-"] {
    position: relative;
    overflow: hidden;
    height: 0;
    padding-bottom: 56.25%;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  & em,
  & strong {
    font-weight: bold;
  }

  & em {
    font-style: normal;
  }

  & strong {
    box-shadow: 0 -1em 0 ${Settings.colors.yellowClearColor} inset;
  }

  & > ul,
  & > ol {
    margin: 1.5rem 0;
    padding-left: 2rem;

    & > li {
      margin: .2rem 0;
    }
  }

  & > ul {
    list-style: circle;
  }

  & > ol {
    list-style: decimal;
  }

  & table {
    table-layout: fixed;
    max-width: 100%;
    margin: 1.6rem 0;
    border-collapse: collapse;
    border-top: 1px solid ${Settings.colors.borderSolidColor};
    border-right: 1px solid ${Settings.colors.borderSolidColor};
    background-color: ${Settings.colors.whiteColor};

    & th,
    & td {
      padding: .6rem 1rem;
      border-bottom: 1px solid ${Settings.colors.borderSolidColor};
      border-left: 1px solid ${Settings.colors.borderSolidColor};
    }

    & th {
      background-color: ${Settings.colors.grayLightColor};
    }

    & td {
      & img {
        display: block;
        max-width: 100%;
        height: auto;
      }
    }
  }

  & > blockquote {
    margin: 1.5rem 0;
    position: relative;
    padding: .5rem 0;

    ${Media.desktop`
      margin-left: -2rem;
      padding-left: 2rem;
    `}

    ${Media.mobile`
      padding-left: 1.5rem;
    `}

    & > *:first-child {
      margin-top: 0;
    }

    & > *:last-child {
      margin-bottom: 0;
    }

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: .2rem;
      content: "";
      display: block;
      background-color: ${Settings.colors.keyColor};
      border-radius: 100px;
    }
  }

  & a {
    color: ${Settings.colors.linkColor};
    text-decoration: underline;
  }

  ${Media.mobile`
    & > h1 {
      font-size: ${Settings.fonts.fontSizeLarge};
    }
    & > h2 {
      font-size: ${Settings.fonts.fontSizeMedium};
    }
    & > h3 {
      font-size: ${Settings.fonts.fontSizePc};
    }
    & table {
      display: inline-block;
      position: relative;
      -webkit-text-size-adjust: 100%;

      & thead {
        display: block;
        float: left;
        overflow-x: auto;
        max-width: 30%;
        border-right: 1px solid ${Settings.colors.borderSolidColor};
        white-space: nowrap;
        -webkit-overflow-scrolling: touch;

        & tr {
          &:first-child {
            & th {
              &:first-child {
                &::before {
                  content: "\\00A0";
                }
              }
            }
          }
        }
      }
      & tbody {
        display: block;
        position: relative;
        overflow-x: auto;
        width: auto;
        background: linear-gradient(to left, rgba(${Settings.colors.whiteColor}, 0), ${Settings.colors.grayColor}) 0 / 2rem 100%,
        linear-gradient(to right, rgba(${Settings.colors.whiteColor}, 0), ${Settings.colors.grayColor}) right / 2rem 100%;
        background-repeat: no-repeat;
        white-space: nowrap;
        background-attachment: scroll;
        -webkit-overflow-scrolling: touch;

        & > tr {
          margin: 0 -6px 0 -1px;
          background-repeat: no-repeat;

          &:last-child {
            background: linear-gradient(to right, rgba(${Settings.colors.whiteColor}, 0), ${Settings.colors.whiteColor});
          }

          &:first-child {
            background: linear-gradient(to left, rgba(${Settings.colors.whiteColor}, 0), ${Settings.colors.whiteColor});
          }
        }
      }

      & th,
      & td {
        display: block;
        text-align: left;
        & > br {
          display: none;
        }
      }

      & tr {
        display: inline-block;
      }
    }
  `}
`;

const Content = ({ body }) => (
  <Container role="article" dangerouslySetInnerHTML={{ __html: body }} />
)

Content.propTypes = {
  body: PropTypes.string,
}

export default Content
