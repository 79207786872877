import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import IcoTwitter from "src/images/ico_twitter.svg"
import IcoFacebook from "src/images/ico_facebook.svg"
import IcoLine from "src/images/ico_line.svg"

import { Media } from 'src/styles/mixins'

import Settings from 'src/styles/settings'

const Container = styled.aside`

  ${Media.desktop`
    margin-bottom: 2rem;
  `}

  ${Media.mobile`
    margin-bottom: 1rem;
  `}
`;

const List = styled.ul`
  display: flex;
  justify-content: center;
  list-style: none;
  margin: 0;
  padding: 0;
`;

const Item = styled.li`
  margin: 0 1rem;
`;

const ItemLink = styled.a`
  display: block;
  width: 3rem;
`;

const TwitterImage = styled(IcoTwitter)`
  width: 100%;
  height: auto;
`;

const FacebookImage = styled(IcoFacebook)`
  width: 100%;
  height: auto;
`;

const LineImage = styled(IcoLine)`
  width: 100%;
  height: auto;
`;

const Share = ({ title, url, twitter, settings }) => (
  <Container>
    <List>
      <Item>
        <ItemLink href={`http://twitter.com/share?url=${url}&text=${title}&related=${ settings.twitter },${ twitter && twitter }&hashtags=${settings.hashTag}`} title="" target="_blank" rel="noreferrer noopener">
          <TwitterImage />
        </ItemLink>
      </Item>
      <Item>
        <ItemLink href={`https://www.facebook.com/sharer.php?u=${url}&t=${title}`} title="" target="_blank" rel="noreferrer noopener">
          <FacebookImage />
        </ItemLink>
      </Item>
      <Item>
        <ItemLink href={`https://line.me/R/msg/text/?${title}%0D%0A${url}`} title="" target="_blank" rel="noreferrer noopener">
          <LineImage />
        </ItemLink>
      </Item>
    </List>
  </Container>
)

Share.propTypes = {
  title: PropTypes.string,
  url: PropTypes.string,
  twitter: PropTypes.string,
  settings: PropTypes.object,
}

export default Share
