import React from 'react'
import { graphql } from 'gatsby'

import Layout from 'src/components/layout'
import SEO from 'src/components/seo'

import Featured from 'src/components/molecules/Featured'
import Content from 'src/components/molecules/Content'

const Page = ({ data }) => {
  const { title, excerpt, content, slug, image } = data.contentfulPages
  const settings = data.contentfulSettings
  const url = `https://${ settings.domain }/${ slug }`

  return (
    <Layout pageTitle={ title }>
      <SEO
        title={ title }
        description={ content.childMarkdownRemark.excerpt.replace(/\r?\n/g, '') }
        keywords={ title }
        url={ url } />

      <Featured
        title={ title }
        excerpt={ excerpt.childMarkdownRemark.html }
        image={ image && image.fluid }
        settings={ settings } />

      <Content body={ content.childMarkdownRemark.html } />
    </Layout>
  )
}

export default Page

export const query = graphql`
  query($slug: String!) {
    contentfulPages(slug: { eq: $slug }) {
      id
      title
      slug
      image {
        fluid(maxWidth: 2000) {
          ...GatsbyContentfulFluid
        }
      }
      excerpt {
        childMarkdownRemark {
          excerpt
          html
        }
      }
      content {
        childMarkdownRemark {
          excerpt
          html
        }
      }
    },

    contentfulSettings(node_locale: {eq: "ja-JP"}) {
      siteName
      siteCaption
      siteDescription
      domain
      twitter
      facebook
      instagram
      line
      hashTag
    }
  }
`